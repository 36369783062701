const DEFAULT_CENTER = { lat: 10, lng: 100 };

const SVGIcons = {
  TRASH:
    '<svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z"/></svg>',
};

const defaultPolygonLayerOptions = {
  strokeColor: "red",
  strokeOpacity: 0.4,
  strokeWeight: 2,
  fillColor: "red",
  fillOpacity: 0.15,
  clickable: true,
  zIndex: -1,
};

const defaultPolygonOptions = {
  ...defaultPolygonLayerOptions,
  strokeOpacity: 0.8,
  fillOpacity: 0.5,
  zIndex: 1,
};

const GeometryType = {
  POLYGON: "Polygon",
  POINT: "Point",
};

/**
 * Google maps requires a complete GeoJSON file, whereas we're dealing with
 * partial/individual geometries only. So, we need to convert.
 */
function geometryToGeoJson(geometry) {
  const ret = {
    type: "FeatureCollection",
    features: [],
  };
  if (geometry) {
    ret.features.push({ type: "Feature", properties: {}, geometry: geometry });
  }
  return ret;
}

/**
 * Returns the bounds of the feature.
 */
function getDataLayerFeatureBounds(feature) {
  const bounds = new google.maps.LatLngBounds();
  const geometry = feature.getGeometry();

  function processPoints(geometry, callback, thisArg) {
    if (geometry instanceof google.maps.Data.Point) {
      callback.call(thisArg, geometry.get());
    } else if (
      geometry instanceof google.maps.Data.MultiPoint ||
      geometry instanceof google.maps.Data.LineString ||
      geometry instanceof google.maps.Data.LinearRing
    ) {
      geometry.getArray().forEach(function (point) {
        callback.call(thisArg, point);
      });
    } else if (
      geometry instanceof google.maps.Data.Polygon ||
      geometry instanceof google.maps.Data.MultiLineString
    ) {
      geometry.getArray().forEach(function (lineOrRing) {
        processPoints(lineOrRing, callback, thisArg);
      });
    } else if (geometry instanceof google.maps.Data.MultiPolygon) {
      geometry.getArray().forEach(function (polygon) {
        processPoints(polygon, callback, thisArg);
      });
    }
  }

  processPoints(geometry, bounds.extend, bounds);
  return bounds;
}

/**
 * Convert an overlay originating from the DrawingManager to a data layer feature.
 */
function overlayToDataLayerFeature(type, overlay) {
  switch (type) {
    case google.maps.drawing.OverlayType.MARKER:
      return new google.maps.Data.Feature({
        geometry: new google.maps.Data.Point(overlay.position),
      });
      break;
    case google.maps.drawing.OverlayType.RECTANGLE:
      var b = overlay.getBounds(),
        p = [
          b.getSouthWest(),
          {
            lat: b.getSouthWest().lat(),
            lng: b.getNorthEast().lng(),
          },
          b.getNorthEast(),
          {
            lng: b.getSouthWest().lng(),
            lat: b.getNorthEast().lat(),
          },
        ];
      return new google.maps.Data.Feature({
        geometry: new google.maps.Data.Polygon([p]),
      });
      break;
    case google.maps.drawing.OverlayType.POLYGON:
      return new google.maps.Data.Feature({
        geometry: new google.maps.Data.Polygon([overlay.getPath().getArray()]),
      });
      break;
    case google.maps.drawing.OverlayType.POLYLINE:
      return new google.maps.Data.Feature({
        geometry: new google.maps.Data.LineString(overlay.getPath().getArray()),
      });
      break;
    case google.maps.drawing.OverlayType.CIRCLE:
      return new google.maps.Data.Feature({
        properties: {
          radius: overlay.getRadius(),
        },
        geometry: new google.maps.Data.Point(overlay.getCenter()),
      });
      break;
  }
  return null;
}

/**
 * Adding HEC Areas GeoJsonFeatures from API as a background map layer
 */
class BackgroundLayer {
  constructor(map, infoWindow) {
    const data = (this.data = new google.maps.Data());
    data.setMap(map);
    data.setStyle(defaultPolygonLayerOptions);
    document.getElementById("info-box").textContent = "";
    data.addListener("click", (event) => {
      document.getElementById("info-box").textContent =
        "HEC area: " + event.feature.getProperty("slug");
    });
  }

  loadGeoJson(geoJsonUrl) {
    this.data.loadGeoJson(geoJsonUrl);
  }
}

/**
 * Controls presentation & interaction with the GeoJSON drawing.
 *
 * TODO:
 * - Prepare for line object as I believe this will be useful in future
 * - Team asks for an import of GeoJson. This is why Debugging window is anabled.
 *   However team don't understand the complexity of GeoJson and tries to import
 *   multiPolygon into Polygon etc. Not sure how to proceed. It can wait to reduce
 *   complexity.
 */
class DrawingController {
  /**
   * Constructor
   */
  constructor(
    map,
    infoWindow,
    geometryType,
    editable,
    valueInput,
    geoJsonModal,
  ) {
    this.geoJsonModal = geoJsonModal;
    this.infoWindow = infoWindow;
    this.editable = editable;
    this.overlays = [];
    this.map = map;
    this.valueInput = valueInput;
    this.geometryType = geometryType;
    if (this.editable) {
      this.drawingManager = new google.maps.drawing.DrawingManager(
        this.getDrawingManagerOptions(),
      );
      this.drawingManager.setMap(map);
      this.setupTrashButton();
      this.setupGeoJsonModalButton();
      this.drawingManager.addListener("overlaycomplete", (e) =>
        this.onOverlayComplete(e),
      );
    }

    map.data.addListener("click", (e) => this.onClickData(e));
  }

  /**
   * Drawing manager options.
   */
  getDrawingManagerOptions() {
    const drawingModes = [];
    switch (this.geometryType) {
      case GeometryType.POLYGON:
        drawingModes.push(google.maps.drawing.OverlayType.POLYGON);
        break;
      case GeometryType.POINT:
        drawingModes.push(google.maps.drawing.OverlayType.MARKER);
        break;
      default:
        throw new Error(`Unexpected geometry type: ${this.geometryType}`);
    }

    return {
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: drawingModes,
      },
      polygonOptions: {
        ...defaultPolygonOptions,
        editable: true,
        draggable: true,
      },
      markerOptions: {
        draggable: true,
      },
    };
  }

  /**
   * Adds the "🗑️" button.
   */
  setupTrashButton() {
    const btn = document.createElement("button");
    btn.innerHTML = SVGIcons.TRASH;
    this.setToolbarButtonProps(btn);
    btn.style.fontSize = "0px";
    btn.style.width = "24px";
    btn.style.margin = "5px 0 0 0";
    btn.title = "Erase drawing";
    btn.addEventListener("click", (e) => this.onTrash(e));
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(btn);
  }

  /**
   * Adds the view-source button.
   */
  setupGeoJsonModalButton() {
    const btn = document.createElement("button");
    btn.textContent = "{...}";
    this.setToolbarButtonProps(btn);
    btn.style.fontSize = "14px";
    btn.title = "View source";
    btn.addEventListener("click", (e) => this.onGeoJsonModal(e));
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(btn);
  }

  /**
   * Shared toolbar button props.
   */
  setToolbarButtonProps(btn) {
    btn.style.backgroundColor = "#fff";
    btn.style.border = "2px solid #fff";
    btn.style.borderRadius = "2px";
    btn.style.boxShadow = "0 1px 2px rgba(0,0,0,.2)";
    btn.style.color = "rgb(25,25,25)";
    btn.style.cursor = "pointer";
    btn.style.lineHeight = "24px";
    btn.style.height = "24px";
    btn.style.margin = "5px 0 0 0";
    btn.style.padding = "0";
    btn.style.textAlign = "center";
    btn.type = "button";
  }

  onGeoJsonModal() {
    if (!this.modal) {
      this.modal = new bootstrap.Modal(this.geoJsonModal);
      this.modalTextarea = this.geoJsonModal.querySelector("textarea");
      this.modalApplyBtn =
        this.geoJsonModal.querySelector("button.btn-primary");
      this.modalApplyBtn.addEventListener("click", (e) =>
        this.onGeoJsonModalApply(e),
      );
    }
    this.updateInputValue((src) => (this.modalTextarea.value = src));
    this.modal.show();
  }

  onGeoJsonModalApply(event) {
    const value = this.modalTextarea.value;
    try {
      const obj = JSON.parse(value);
      this.init(obj);
      this.valueInput.value = value;
      this.modal.hide();
    } catch {
      alert("Input error");
    }
  }

  /**
   * Polygon vertices were changed.
   */
  onPolygonVertexEvent(event) {
    this.queueUpdateInputValue();
  }

  /**
   * Marker was dragged
   */
  onMarkerDragEnd(event) {
    this.infoWindow.close();
    const advancedMarker = this.overlays[0].overlay;
    const position = advancedMarker.position;
    this.infoWindow.setContent(
      `Pin dropped at: ${position.lat.toFixed(5)}, ${position.lng.toFixed(5)}`,
    );
    this.infoWindow.open(this.map, advancedMarker);
    this.queueUpdateInputValue();
  }

  /**
   * A new overlay is drawn.
   */
  onOverlayComplete(event) {
    this.clear();
    let { type, overlay } = event;
    if (type === google.maps.drawing.OverlayType.MARKER) {
      overlay.setMap(null);
      // Swap out marker for advanced one.
      overlay = this.createAdvancedMarker({
        lat: overlay.getPosition().lat(),
        lng: overlay.getPosition().lng(),
      });
    }
    this.drawingManager.setDrawingMode(null);
    this.registerOverlay(type, overlay);
    this.queueUpdateInputValue();
  }

  /**
   * A click on a feature in the data layer.
   */
  onClickData(event) {
    // Maybe in the future make the clicked feature editable?
  }

  /**
   * The 🗑️ was clicked.
   */
  onTrash(e) {
    this.clear();
    e.preventDefault();
    this.queueUpdateInputValue();
    return false;
  }

  /**
   * Hook up the newly drawn overlay.
   */
  registerOverlay(type, overlay) {
    const ovl = { type, overlay };
    this.overlays.push(ovl);
    switch (type) {
      case google.maps.drawing.OverlayType.POLYGON:
        ["set_at", "insert_at", "remove_at"].forEach((eventName) =>
          google.maps.event.addListener(overlay.getPath(), eventName, (e) =>
            this.onPolygonVertexEvent(e),
          ),
        );
        break;
      case google.maps.drawing.OverlayType.MARKER:
        overlay.addListener("dragend", (e) => this.onMarkerDragEnd(e));
        break;
      default:
        console.error(`Registering unsupported overlay type: ${type}`);
    }
    return ovl;
  }

  /**
   * Schedule updating the input value.
   */
  queueUpdateInputValue() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
    this.timeoutId = setTimeout(() => this.updateInputValue(), 200);
  }

  /**
   * Populate ther underlying <input> with the GeoJSON encoded value
   */
  updateInputValue(cb) {
    let called = false;
    let value = "";
    this.valueInput.value = value;
    if (this.overlays.length > 0) {
      const ov = this.overlays[0];
      const feature = overlayToDataLayerFeature(ov.type, ov.overlay);
      if (feature) {
        called = true;
        feature.toGeoJson((geoJsonFeature) => {
          value = JSON.stringify(geoJsonFeature.geometry);
          this.valueInput.value = value;
          if (cb) {
            cb(value);
          }
        });
      }
    }
    if (!called && cb) {
      cb(value);
    }
  }

  /**
   * Erase the whole drawing.
   */
  clear() {
    this.overlays.forEach((ov) => ov.overlay.setMap(null));
    this.overlays = [];
    this.map.data.forEach((feature) => {
      this.map.data.remove(feature);
    });
  }

  /**
   * Create an advanced marker.
   */
  createAdvancedMarker(position) {
    const marker = new google.maps.marker.AdvancedMarkerElement({
      map: this.map,
      position: position,
      gmpDraggable: true,
    });
    return marker;
  }

  /**
   * Converts a feature from the data layer to an (editable) overlay.
   */
  dataFeatureToDrawingManagerOverlay(feature) {
    const geometry = feature.getGeometry();
    const geometryType = geometry.getType();
    if (this.geometryType !== geometryType) {
      console.error(`Expected ${this.geometryType}, got ${geometryType}`);
      return null;
    }
    switch (geometryType) {
      case GeometryType.POINT: {
        const marker = this.createAdvancedMarker({
          lat: geometry.get().lat(),
          lng: geometry.get().lng(),
        });
        return this.registerOverlay(
          google.maps.drawing.OverlayType.MARKER,
          marker,
        );
      }
      case GeometryType.POLYGON: {
        const polygon = new google.maps.Polygon({
          ...defaultPolygonOptions,
          editable: true,
          draggable: true,
        });
        polygon.setOptions({
          paths: geometry
            .getArray()[0]
            .getArray()
            .map((ll) => {
              return { lat: ll.lat(), lng: ll.lng() };
            }),
        });
        polygon.setMap(this.map);
        return this.registerOverlay(
          google.maps.drawing.OverlayType.POLYGON,
          polygon,
        );
      }
    }
    return false;
  }

  /**
   * Returns the style for a data layer feature.
   */
  getDataFeatureStyle(feature) {
    return defaultPolygonOptions;
  }

  /**
   * Center
   */
  recenter() {
    let center = DEFAULT_CENTER;
    let bounds = null;
    if (this.overlays.length > 0) {
      const ov = this.overlays[0];
      const feature = overlayToDataLayerFeature(ov.type, ov.overlay);
      if (feature) {
        bounds = getDataLayerFeatureBounds(feature);
      }
    } else {
      this.map.data.forEach((feature) => {
        // For now there is no need to average, there is just one.
        bounds = getDataLayerFeatureBounds(feature);
      });
    }
    if (bounds) {
      center = bounds.getCenter();
    }
    if (!bounds || this.geometryType === GeometryType.POINT) {
      this.map.setCenter(center);
    } else {
      this.map.fitBounds(bounds);
    }
  }

  /**
   * Initializes.
   */
  init(geometryObject) {
    const geoJson = geometryToGeoJson(geometryObject);
    const features = this.map.data.addGeoJson(geoJson);
    this.map.data.setStyle((feature) => this.getDataFeatureStyle(feature));
    if (this.editable && features.length) {
      this.clear();
      // There might be multiple geometries in case user paste's in geojson from elsewhere.
      // Let's pick the first feature that converts.
      for (let i = 0; i < features.length; i++) {
        const ov = this.dataFeatureToDrawingManagerOverlay(features[i]);
        if (ov) {
          break;
        }
      }
    }
    this.recenter();
  }
}

async function initMap (options) {
  /**
   * Fetches input value, if not fetch template value, if not return false
   */
  function getObjectParsed() {
    let value = null;
    if (document.getElementById(options.id).value) {
      value = document.getElementById(options.id).value;
    } else if (options.value) {
      value = options.value;
    } else {
      console.info("No object, defaulted to options.defaultLocation ");
      return false;
    }
    try {
      value = JSON.parse(value);
    } catch {}
    if (typeof value !== "object") {
      console.error("Invalid GeoJSON geometry value");
      value = null;
    }
    return value;
  }

  async function init() {
    // Request needed libraries.
    const { DrawingManager } = await google.maps.importLibrary("drawing");
    const { Map, InfoWindow } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    // Init map
    const map = new Map(document.getElementById(options.mapId), {
      center: DEFAULT_CENTER,
      zoom: options.mapZoom,
      mapId: options.googleMapId,
    });

    // Init InfoWindow
    const infoWindow = new google.maps.InfoWindow();

    const backgroundLayer = new BackgroundLayer(map, infoWindow);
    backgroundLayer.loadGeoJson(options.geoJsonFeaturesUrl);

    const geoJsonModal = document.getElementById(options.geoJsonModalId);
    const valueInput = document.getElementById(options.id);
    const ctrl = new DrawingController(
      map,
      infoWindow,
      options.geomName,
      options.editable,
      valueInput,
      geoJsonModal,
    );
    ctrl.init(getObjectParsed());
  }

  await init();
};


class HECMap extends HTMLElement {
    connectedCallback() {
        const options = {
            geomName: this.getAttribute('geom-name'),
            id: this.getAttribute("input-id"),
            mapId: this.getAttribute("map-id"),
            mapSrid: this.getAttribute("map-srid"),
            name: this.getAttribute("name"),
            mapZoom: parseInt(this.getAttribute("map-zoom"), 10),
            editable: this.hasAttribute("editable"),
            value: this.getAttribute("value"),
            geoJsonModalId: this.getAttribute("geojson-modal-id"),
            geoJsonFeaturesUrl: this.getAttribute("geojson-features-url"),
            googleMapId: this.getAttribute("google-map-id"),
            defaultLocation: this.getAttribute("default-location")
        }
        initMap(options)
    }
}

customElements.define('hec-map', HECMap)
