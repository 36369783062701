////////////////////////////////////////////////////////////////////////////////////
// Catalog elephant image uploader with cropper
////////////////////////////////////////////////////////////////////////////////////

const mapFormIdCropper = {};
let registeredFormIds = [];

const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
}

const registerInputPreview = () => {
  const imageInputElements = document.querySelectorAll('.elephant_image_upload_form');

  imageInputElements.forEach((imageInputElement) => {
    const imageElement = document.querySelector(imageInputElement.dataset.targetImage);

    if (!imageInputElement) {
      return
    }

    imageInputElement.addEventListener('change', (event) => {
      if (!event.target.files || !event.target.files.length) {
        return
      }

      const [file] = event.target.files;

      if (file) {
        imageElement.setAttribute('src', URL.createObjectURL(file))
        imageInputElement.querySelector('.btn_save').removeAttribute('disabled');
      }
    })
  })
}

// Script for image details
document.addEventListener('DOMContentLoaded', () => {
  registerInputPreview()
}, false);


window.showCropperModal = (formSelector) => {
  const formElement = document.querySelector(formSelector);

  if (!formElement) {
    return
  }

  const imageElement = document.querySelector(formElement.dataset.targetImage);
  const modalElement = document.querySelector(formElement.dataset.targetModal);
  const modal = new window.bootstrap.Modal(modalElement);

  if (!registeredFormIds.includes(formElement.id)) {
    modalElement.addEventListener('shown.bs.modal', () => {
      mapFormIdCropper[formElement.id] = new window.Cropper(imageElement, {
      });
    });

    modalElement.addEventListener('hide.bs.modal', () => {
      if (mapFormIdCropper.hasOwnProperty(formElement.id)) {
        mapFormIdCropper[formElement.id].destroy();
        delete mapFormIdCropper[formElement.id];
      }
    });

    registeredFormIds.push(formElement.id);
  }

  modal?.show();
}

window.getCroppedImage = (formSelector) => {
  const formElement = document.querySelector(formSelector);

  if (!formElement) {
    return
  }

  if (!mapFormIdCropper.hasOwnProperty(formElement.id)) {
    return
  }

  const inputElement = formElement.querySelector(`input`);
  const filename = inputElement.files[0].name;
  const cropper = mapFormIdCropper[formElement.id];
  const croppedImageBase64 = cropper.getCroppedCanvas().toDataURL("image/jpeg");
  const croppedFile = dataURLtoFile(croppedImageBase64, cropper, filename);
  const formData = new FormData();

  formData.append('image', croppedFile, filename);

  return Object.fromEntries(formData.entries())
}

document.addEventListener('htmx:beforeRequest', (event) => {
  const element = event.detail.target;

  if (element.classList.contains('elephant_image_upload_form')) {
    const modalElements = document.querySelectorAll('.modal.show');

    modalElements.forEach((modalElement) => {
      const modal = window.bootstrap.Modal.getInstance(modalElement);

      if (registeredFormIds.includes(element.id)) {
        registeredFormIds = registeredFormIds.filter((id) => id !== element.id);
      }

      modal?.hide();
    })
  }
});

document.addEventListener('htmx:afterOnLoad', (event) => {
  registerInputPreview()
})
